import React from "react"
import styled from "styled-components"
import Form from "./form"
import Result from "./result"

const StyledContainer = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  }
`


const Linking = (props) => {
  return (
    <StyledContainer
      color="#9F7AEA"
    >
      <Form
        postDocumentLinking={props.postDocumentLinking}
        response={props.response}
        params={props.queryParams}
        text={props.queryParams?.text || null}
      />
      {props.queryParams?.text ?
        (
          <Result 
            getDocumentLinking={props.getDocumentLinking}
            linkingResult={props.linkingResult}
            text={props.queryParams.text}
            email={props.queryParams.email}
          />
        )
        :
        (
          ''
        )
      }
    </StyledContainer>
  )
}


export default Linking