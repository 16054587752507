import React, {useState, useEffect} from "react"
import styled from "styled-components"


const StyledContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  line-height: 1.3rem;
  font-size: 1rem;
`
const Ul = styled.ul`
  margin-top: 10px;
  list-style: none;
  padding-left: 0;
  width: 100%;
`

const Li = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.zodiacLight};
  &:last-of-type {
    margin-bottom: 0;
  }

`

const Div = styled.div`
  width: 80%;
`

const Label = styled.span`
  width: 12rem;
  min-width: 12rem;
  max-width: 12rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 1rem;
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 20rem;
    min-width: 20rem;
    max-width: 20rem;
  }
`
const Progress = styled.div`
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  background-color: #DFE7EF;
  border-radius: 0.5rem;
  width: 100%;
  margin-bottom: 0.5rem;

  .inner {
    background-color: #5628B4!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
  }
`

const Linking = (props) => {
  const linking = props.linkingResult
  return (
    <StyledContainer color="#9F7AEA">
      <Ul>
        {
          linking.map((item, index) => (
            <Li key={`Linking-${index}`}>
              <Div>
                <Progress>
                  <div
                    className="inner"
                    style={{ width: `${Math.round(item[1]*100)}%`}}
                  > 
                  </div>
                </Progress>
                <Label>{item[0]}</Label>
              </Div>
              <span>{Math.round(item[1]*100)}%</span>
            </Li>
            )
          )
        }
      </Ul>
    </StyledContainer>
  )
}


export default Linking
